<template>
  <div class="flex w-full flex-col">
    <loader :loading="loading" :backdrop="true"/>
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-xl">Usuarios</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">
      Editando Usuario: {{user.user_information.first_name}} {{user.user_information.last_name}}
    </h4>
    <section class="w-full flex flex-col px-2 py-3 bg-white">
      <div class="header">
        <h3 class="text-start font-normal text-sm">Usuarios</h3>
        <div class="flex">
          <div class="flex p-0.5 mr-1 bg-green-500 rounded self-center">
            <icon
              @click.native="editUser"
              class="text-white" name="FlatCheckIcon" />
          </div>
          <div class="flex p-0.5 bg-gray-300 ml-1 rounded self-center">
            <icon
              @click.native="() => $router.back()"
              class="text-white" name="XIcon" />
          </div>
        </div>
      </div>
      <div class="w-full mt-2">
        <b-card class="nav-tabs-custom" no-body>
          <b-tabs
            v-model="tabIndex"
            small
            card>
            <b-tab title="Datos de la cuenta" active :title-link-class="linkClass(0)">
              <b-card-text><AccountData /></b-card-text>
            </b-tab>
            <b-tab title="Contacto y Dirección" :title-link-class="linkClass(1)">
              <b-card-text><ContactAndAddress /></b-card-text>
            </b-tab>
            <b-tab title="Más" :title-link-class="linkClass(2)">
              <b-card-text><More /></b-card-text>
            </b-tab>
            <b-tab title="Permisos" :title-link-class="linkClass(3)">
              <b-card-text><Permissions /></b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Icon from '@/components/Icon.vue';
import AccountData from '@/views/users/Edit/AccountData.vue';
import ContactAndAddress from '@/views/users/Edit/ContactAndAddress.vue';
import More from '@/views/users/Edit/More.vue';
import Permissions from '@/views/users/Edit/Permissions.vue';
import request from '@/api/request-services';
import UserMixin from '@/mixins/UserMixin';
import Loader from '@/components/Loader.vue';

export default {
  name: 'EditUser',
  mixins: [UserMixin],
  components: {
    BreadCrumbs,
    Icon,
    AccountData,
    ContactAndAddress,
    More,
    Permissions,
    Loader,
  },
  data() {
    return {
      tabIndex: 0,
      routes: [
        { name: 'Inicio', path: '/' },
        { name: 'Informacion de Usuario', path: '/user_info/23' },
        { name: 'Editar Usuario', path: '/edit-user/23' },
      ],
      loading: false,
      // user: {
      //   id: '',
      //   name: '',
      //   accountType: '',
      //   company: 'Unknown',
      //   email: '',
      //   code: 2343,
      //   idNumber: '1-1638-0986',
      //   state: 'Activo',
      //   primaryNumber: '88888888',
      //   secondaryNumber: '25432343',
      //   accountCompleted: 'Si',
      //   verified: 'Si',
      //   store: 'Cartago',
      //   user_information: {
      //     first_name: '',
      //     last_name: '',
      //     company: '',
      //     identification: '',
      //     identification_type: [],
      //   },
      // },
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['border-t border-red-500', 'text-dark'];
      }
      return ['bg-light', 'text-dark'];
    },
    async handleUserInfo() {
      this.loading = true;
      const response = await request.requestGET(`user/${this.$route.params.id}`, this.getToken);
      if (response.status === 200) {
        this.setCat(response.data);
        this.loading = false;
      } else {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'No se pudo cargar la información del usuario, por favor intenta de nuevo',
        });
        this.loading = false;
      }
    },
    async editUser() {
      this.loading = true;
      const data = {
        first_name: this.user.user_information.first_name,
        last_name: this.user.user_information.last_name,
        company: this.user.user_information.company,
        email: this.user.email,
        identification_type: this.user.user_information.identification_type,
        identification: this.user.user_information.identification,
        gender: this.user.user_information.gender,
        phone: this.user.user_information.phone,
        phone_ext: this.user.user_information.phone_ext,
        mobile: this.user.user_information.mobile,
        work: this.user.user_information.work,
        work_ext: this.user.user_information.work_ext,
        other: this.user.user_information.other,
        other_ext: this.user.user_information.other_ext,
        verified: this.user.verified,
        discount: this.user.user_information.discount,
        protected: this.user.protected,
        subscribed: this.user.subscribed,
        account_type: this.user.user_information.account_type,
        account_complete: this.user.account_complete,
        send_electronic_invoice: this.user.user_information.send_electronic_invoice,
        pin: this.user.pin,
        last_exemption_date: this.user.user_information.last_exemption_date,
        price_per_pound: this.user.user_information.price_per_pound,
        enabled: this.user.enabled,
      };
      const response = await request.requestPUT(`user/${this.$route.params.id}`, data, this.getToken);
      if (response.status === 200) {
        this.$notify({
          type: 'success',
          title: 'Correcto',
          text: 'El usuario ha sido editado',
        });
        this.loading = false;
      } else {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'El usuario no ha sido editado, por favor intenta de nuevo',
        });
        this.loading = false;
      }
    },
  },
  mounted() {
    this.handleUserInfo();
  },
};
</script>
<style scoped>
  .header {
    @apply flex justify-between p-1 border-b border-gray-300;
  }

  .nav-tabs-custom {
    margin-bottom: -0.5rem !important;
  }
</style>
