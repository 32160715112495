<template>
  <div class="w-full flex flex-col">
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Reporte de prueba</h4>
     <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form >
          <div v-if="isToggle">
            <slot name="filters">
              this are the default filters
            </slot>
          </div>
        </b-form>
      </div>
    </section>
    <section class="w-full bg-white p-2 mt-4">
      <div class="w-full flex justify-start">
        <label class="mb-4">Reporte</label>
      </div>
       <slot name="report-detail">
          this is the default report detail
        </slot>
    </section>
  </div>
</template>

<script>
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';

export default {
  components: {
    PlusIcon,
    MinusIcon,
  },
  data() {
    return {
      isToggle: false,
    };
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>
