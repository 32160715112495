<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-2 text-2xl mb-2">Reporte</h3>
    <ReportTemplate>
      <template v-slot:filters>
        <div class="grid grid-cols-4 gap-4 mt-4 px-2 pb-4" >
          <select-input
          idInput="dateRange"
          idFormGroup="filter-group"
          label="Formato de Rango de fecha"
          />
          <input-field
            class="text-sm"
            idInput="discount"
            idFormGroup="email-group"
            type="date"
            classLabel="mt-1"
            classInput="input-filter"
            label="Desde"
          />
           <input-field
            class="text-sm"
            idInput="discount"
            idFormGroup="email-group"
            type="date"
            classLabel="mt-1"
            classInput="input-filter"
            label="Hasta"
          />
          <div>
            <select-input
                idInput="dateRange"
                idFormGroup="filter-group"
                label="Rango de fecha"
              />
          </div>
          <div>
            <div class="bg-blue-700 rounded p-2 text-center mt-6 cursor-pointer">
              <icon
                class="text-white inline"
                name="FilterIcon" />
              <p  class="text-white inline ml-1">Filtrar</p>
            </div>
          </div>
          <div>
            <div
              @click="isFilterToggle = !isFilterToggle"
              class="bg-blue-500 rounded p-2 text-center mt-6 cursor-pointer">
              <p  class="text-white inline ml-1">Seleccionar columnas</p>
            </div>
          </div>
        </div>
        <div v-if="isFilterToggle" class="border-3 p-2">
          <p class="px-2">Seleccionar columnas</p>
          <div class="grid grid-cols-5 gap-4  px-2 ">
           <div>
              <label class="cursor-pointer">
                <input type="checkbox" value="value">
                <span class="px-2">Total</span>
              </label>
           </div>
            <div>
              <label class="cursor-pointer">
                <input type="checkbox" value="value">
                <span class="px-2">Fecha</span>
              </label>
           </div>
            <div>
              <label class="cursor-pointer">
                <input type="checkbox" value="value">
                <span class="px-2">Total Facturado</span>
              </label>
           </div>

          </div>
        </div>
      </template>
      <template v-slot:report-detail>
        <p class="text-center">No hay resultados</p>
      </template>
    </ReportTemplate>
  </div>
</template>

<script>
/* eslint-disable global-require */
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import ReportTemplate from '@/components/ReportTemplate.vue';
import SelectInput from '@/components/SelectInput.vue';
import InputField from '@/components/InputField.vue';
import Icon from '@/components/Icon.vue';

export default {
  components: {
    BreadCrumbs,
    ReportTemplate,
    SelectInput,
    InputField,
    Icon,
  },
  data() {
    return {
      isFilterToggle: false,
      routes: [],
    };
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>
