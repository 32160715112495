<template>
  <div class="py-1">
    <div class="collapse-default" @click="isCollapsed = !isCollapsed">
      {{permission.name}}
    </div>
    <transition name="fade" >
      <div  class="pl-6" v-if="!isCollapsed">
        <div v-for="action in permission.actions" :key="action.id">
          <label class="cursor-pointer py-4">
            <input type="checkbox" value="value" class="w-4 h-4 mr-2">
            <span>{{action.name}}</span>
          </label>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CollapsePermissions',
  data() {
    return {
      isCollapsed: true,
    };
  },
  props: {
    permission: {
      type: Object,
      default: () => {},
      require: true,
    },
  },

};
</script>

<style scoped>
  .collapse-default {
    background: rgba(22, 60, 77, 0.87);
    color: #fff;
    border-color: rgba(22, 60, 77, 0.87);
    @apply p-3 rounded-t-md cursor-pointer
  }
.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
  height: auto;
  overflow: hidden;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
  opacity: 0;
}
</style>
