<template>
  <div class="w-full flex grid grid-cols-2 gap-4">
    <select-input
      class="w-full text-sm"
      idInput="idType"
      idFormGroup="idType-group"
      label="Permiso de rol"
      :options="rolePermissions"
      :valueField="'id'"
      :textField="'display_name'"
    />
    <radio-buttons
      class="w-full text-sm"
      idInput="status-input"
      idFormGroup="status-group"
      label="Estado"
      :options="statusOptions"
      v-model="user.enabled"
    />
    <radio-buttons
      class="w-full text-sm"
      idInput="verified-input"
      idFormGroup="verified-group"
      label="Verificado"
      :options="verified"
      v-model="user.verified"
    />
    <input-field
      class="text-sm"
      idInput="discount-input"
      idFormGroup="discount-group"
      type="number"
      label="Descuento"
      v-model="user.user_information.discount"
    />
   <radio-buttons
      class="w-full text-sm"
      idInput="protected-input"
      idFormGroup="protected-group"
      label="Protegido"
      :options="protectedOptions"
      v-model="user.protected"
    />
    <radio-buttons
      class="w-full text-sm"
      idInput="suscribed-input"
      idFormGroup="suscribed-group"
      label="Subscripción al boletín, noticias, ofertas..."
      :options="subscribed"
      v-model="user.subscribed"
    />
     <radio-buttons
      class="w-full text-sm"
      idInput="account_type-input"
      idFormGroup="account_type-group"
      label="Tipo de cuenta"
      :options="accountType"
      :customWidth="'full'"
      v-model="user.user_information.account_type"
    />
    <radio-buttons
      class="w-full text-sm"
      idInput="account_complete-input"
      idFormGroup="account_complete-group"
      label="Cuenta completa"
      :options="account"
      v-model="user.account_complete"
    />
      <radio-buttons
      class="w-full text-sm"
      idInput="send_electronic_invoice-input"
      idFormGroup="send_electronic_invoice-group"
      label="Factura electrónica"
      :options="electronicInvoice"
      v-model="user.user_information.send_electronic_invoice"
    />
     <input-field
      class="text-sm"
      idInput="pin-input"
      idFormGroup="pin-group"
      type="number"
      label="Pin"
      v-model="user.pin"
    />
      <input-field
      class="text-sm"
      idInput="date-input"
      idFormGroup="date-group"
      type="date"
      label="Última fecha de exoneración"
      v-model="user.user_information.last_exemption_date"

    />
      <input-field
      class="text-sm"
      idInput="price_per_pound-input"
      idFormGroup="price_per_pound-group"
      type="number"
      label="Precio por libra"
      v-model="user.user_information.price_per_pound"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import InputField from '@/components/InputField.vue';
import RadioButtons from '@/components/RadioButtons.vue';
import SelectInput from '@/components/SelectInput.vue';
import UserMixin from '@/mixins/UserMixin';
import request from '@/api/request-services';

export default {
  name: 'AccountData',
  mixins: [UserMixin],
  components: {
    InputField,
    RadioButtons,
    SelectInput,
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  data() {
    return {
      // accountData: {
      //   name: '',
      //   lastName: '',
      //   companyName: 'Unknown',
      //   email: '',
      //   username: '',
      //   code: '2397',
      //   idType: 'cedula_fisica',
      //   idNumber: '11490490984',
      //   gender: 'hombre',
      // },
      roles: [
        { text: 'admin', value: 'admin' },
        { text: 'user', value: 'user' },
      ],
      rolePermissions: [],
      statusOptions: [
        { text: 'Inactivo', value: '0' },
        { text: 'Activo', value: '1' },
      ],
      verified: [
        { text: 'Si', value: '1' },
        { text: 'No', value: '0' },
      ],
      protectedOptions: [
        { text: 'Si', value: '1' },
        { text: 'No', value: '0' },
      ],
      referredBy: [
        { text: 'persona 1', value: '1' },
        { text: 'persona 2', value: '2' },
      ],
      agreement: [
        { text: 'convenio 1', value: '1' },
        { text: 'convenio 2', value: '2' },
      ],
      subscribed: [
        { text: 'Si', value: 1 },
        { text: 'No', value: 0 },
      ],
      accountType: [
        { text: 'Personal', value: 'personal' },
        { text: 'Empresarial', value: 'business' },
        { text: 'Envíos familiares', value: 'family' },
      ],
      account: [
        { text: 'Si', value: '1' },
        { text: 'No', value: '0' },
      ],
      allowCredit: [
        { text: 'Si', value: 'si' },
        { text: 'No', value: 'no' },
      ],
      electronicInvoice: [
        { text: 'Si', value: '1' },
        { text: 'No', value: '0' },
      ],
    };
  },
  methods: {
    async handleRoleInfo() {
      const response = await request.requestGET('roles', this.getToken);
      if (response.status === 200) {
        this.rolePermissions = response.data;
      } else {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'No se pudo cargar la información de los roles, por favor intenta de nuevo',
        });
      }
    },
  },
  mounted() {
    this.handleRoleInfo();
  },
};
</script>
