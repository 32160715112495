<template>
    <div>
        <div v-for="permission in permissionList" :key="permission.id">
          <CollapsePermissions :permission="permission" />
        </div>
    </div>
</template>

<script>
import CollapsePermissions from '@/components/CollapsePermissions.vue';

export default {
  name: 'permissions',
  components: {
    CollapsePermissions,
  },
  data() {
    return {
      test: '',
      permissionList: [
        {
          id: 1,
          name: 'Dashboard',
          actions: [
            {
              id: 1,
              name: 'View Dashboard',
            },
          ],
        },
        {
          id: 2,
          name: 'Users',
          actions: [
            {
              id: 1,
              name: 'View Users',
            },
            {
              id: 2,
              name: 'View Clients',
            },
            {
              id: 3,
              name: 'Create Users',
            },
            {
              id: 4,
              name: 'Create Clients',
            },
          ],
        },
      ],
    };
  },
};
</script>
