<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-2 text-2xl mb-2">Reportes</h3>

    <section class="w-full grid grid-cols-4 gap-4 mt-4">
      <router-link v-for="item in menuOptions" :key="item.key"
        :to="{path:'/report', name: 'Report', params: {id: '1'} }"
        class="flex h-auto  bg-white rounded-lg overflow-hidden no-underline">
       <img
          :src="item.img"
          alt="Usuarios Pendientes"
          class="object-cover h-auto w-16">
        <div class="relative w-full">
          <h3 class="text-sm md:text-md font-small text-gray-800 mx-2 mt-2 uppercase">
            {{item.title}}
          </h3>

          <p class=" text-base md:text-ls text-gray-600 mx-2">
            generar reporte
            <Icon class="text-gray-600 inline-block" name="ArrowCircleRightIcon"/>
          </p>
        </div>
      </router-link>
    </section>
  </div>
</template>

<script>
/* eslint-disable global-require */
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Icon from '@/components/Icon.vue';

export default {
  components: { BreadCrumbs, Icon },
  data() {
    return {
      routes: [],
      menuOptions: [
        {
          title: 'Crecimiento',
          key: 1,
          img: require('@/assets/img/crecimientofacturacion.svg'),
        },
        {
          title: 'Afiliaciones',
          key: 2,
          img: require('@/assets/img/afiliaciones.svg'),
        },
        {
          title: 'Peso',
          key: 3,
          img: require('@/assets/img/peso.svg'),
        },
        {
          title: 'Paquetes rechazados',
          key: 4,
          img: require('@/assets/img/paquetesrechazados.svg'),
        },
        {
          title: 'Reporte de facturación',
          key: 5,
          img: require('@/assets/img/reportedefacturacion.svg'),
        },
        {
          title: 'Paquetes',
          key: 6,
          img: require('@/assets/img/paquetes.svg'),
        },
        {
          title: 'Usuarios',
          key: 7,
          img: require('@/assets/img/usuarios.svg'),
        },
        {
          title: 'AWBs',
          key: 8,
          img: require('@/assets/img/awbs.svg'),
        },
        {
          title: 'Impuesto de ventas',
          key: 9,
          img: require('@/assets/img/impuestoventas.svg'),
        },
        {
          title: 'Reporte de recuperación',
          key: 10,
          img: require('@/assets/img/reportederecuperacion.svg'),
        },
        {
          title: 'Reporte de paquetes por peso',
          key: 11,
          img: require('@/assets/img/paquetesporpeso.svg'),
        },
      ],
    };
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>

<style scoped>
  .info-box-icon {
      position: relative;
      border-top-left-radius: 2px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 2px;
      display: block;
      float: left;
      height: auto;
      width: 90px;
      text-align: center;
      font-size: 45px;
      line-height: 90px;
      background: rgba(0,0,0,0.2);
      background-color: #00c0ef;
  }

  .inner-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
  }
</style>
