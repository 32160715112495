<template>
  <div class="w-full flex grid grid-cols-2 gap-4">
    <input-field
      class="text-sm"
      idInput="name"
      idFormGroup="name-group"
      type="text"
      label="Nombre"
      v-model="user.user_information.first_name"
    />
    <input-field
      class="text-sm"
      idInput="lastName"
      idFormGroup="lastName-group"
      type="text"
      label="Apellido"
      v-model="user.user_information.last_name"
    />
    <input-field
      class="text-sm"
      idInput="companyName"
      idFormGroup="companyName-group"
      type="text"
      label="Nombre de la Empresa"
      v-model="user.user_information.company"
    />
    <input-field
      class="text-sm"
      idInput="email"
      idFormGroup="email-group"
      type="email"
      label="Correo Electrónico"
      v-model="user.email"
    />
    <input-field
      class="text-sm"
      idInput="username"
      idFormGroup="username-group"
      type="text"
      label="Nombre de Usuario"
      v-model="user.user_information.first_name"
      disabled
    />
    <input-field
      class="text-sm"
      idInput="code"
      idFormGroup="code-group"
      type="text"
      label="Código"
      v-model="user.id"
      disabled
    />
    <select-input
      class="w-full text-sm"
      idInput="idType"
      idFormGroup="idType-group"
      label="Seleccionar Tipo de Identificación"
      v-model="user.user_information.identification_type"
      :options="idTypes"
    />
    <input-field
      class="text-sm"
      idInput="idNumber"
      idFormGroup="idNumber-group"
      type="text"
      label="Identificación"
      v-model="user.user_information.identification"
    />
    <radio-buttons
      class="w-full text-sm"
      idInput="gender-input"
      idFormGroup="gender-group"
      label="Genero"
      :options="genderOptions"
      v-model="user.user_information.gender"
    />
  </div>
</template>
<script>
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import RadioButtons from '@/components/RadioButtons.vue';
import UserMixin from '@/mixins/UserMixin';

export default {
  name: 'AccountData',
  mixins: [UserMixin],
  components: { InputField, SelectInput, RadioButtons },
  data() {
    return {
      // accountData: {
      //   name: '',
      //   lastName: '',
      //   companyName: 'Unknown',
      //   email: '',
      //   username: '',
      //   code: '2397',
      //   idType: 'cedula_fisica',
      //   idNumber: '11490490984',
      //   gender: 'hombre',
      // },
      idTypes: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Cedula Fisica', value: 1 },
        { text: 'Cedula Judirica', value: 2 },
        { text: 'Pasaporte', value: 3 },
        { text: 'DMEX', value: 4 },
      ],
      genderOptions: [
        { text: 'Hombre', value: 1 },
        { text: 'Mujer', value: 2 },
      ],
    };
  },
};
</script>
