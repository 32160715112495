<template>
  <div class="flex w-full grid grid-cols-1">
    <div class="flex w-full my-2">
        <div class="flex-grow-0 w-1/2 h-16">
          <input-field
            class="text-sm"
            idInput="phone_house"
            idFormGroup="phone_house-group"
            type="number"
            label="Tel.Casa"
            v-model="user.user_information.phone"
          />
        </div>
        <div class="flex-grow-0 w-24 h-16 mx-4">
          <input-field
            class="text-sm"
            idInput="extHouse"
            idFormGroup="extHouse-group"
            type="number"
            label="Ext."
            v-model="user.user_information.phone_ext"
          />
        </div>
        <div class="flex-grow-0 w-1/2 h-16">
          <input-field
            class="text-sm"
            idInput="cellPhone"
            idFormGroup="cellPhone-group"
            type="number"
            label="Tel. Celular"
            v-model="user.user_information.mobile"
          />
        </div>
    </div>
    <div class="flex w-full">
        <div class="flex-grow-0 w-1/2 h-16">
          <input-field
            class="text-sm"
            idInput="workPhone"
            idFormGroup="workPhone-group"
            type="number"
            label="Tel. Trabajo"
            v-model="user.user_information.work"
          />
        </div>
        <div class="flex-grow-0 w-24 h-16 mx-4">
          <input-field
            class="text-sm"
            idInput="extWork"
            idFormGroup="extWork-group"
            type="number"
            label="Ext."
            v-model="user.user_information.work_ext"
          />
        </div>
        <div class="flex-grow-0 w-5/12 h-16 mr-4">
          <input-field
            class="text-sm"
            idInput="otherPhone"
            idFormGroup="otherPhone-group"
            type="number"
            label="Otro"
            v-model="user.user_information.other"
          />
        </div>
        <div class="flex-grow-0 w-24 h-16">
          <input-field
            class="text-sm"
            idInput="extOther"
            idFormGroup="extOther-group"
            type="number"
            label="Ext."
            v-model="user.user_information.other_ext"
          />
        </div>
    </div>
    <div class="flex w-full">
      <div class="flex-grow-0 w-7/12 h-16 mr-3">
          <radio-buttons
            class="w-full text-sm"
            idInput="delivery-input"
            idFormGroup="delivery-group"
            label="Entregar en"
            :customWidth="'48'"
            :options="deliveryOptions"
            v-model="user.user_information.deliver_to"
          />
        </div>
        <div class="flex-grow-0 w-1/2 ml-2 h-16">
          <input-field v-if="user.user_information.deliver_to === 'address'"
            class="text-sm"
            idInput="address-input"
            idFormGroup="address-group"
            type="text"
            label="Dirección"
          />
          <select-input v-else
            class="w-full text-sm"
            idInput="store"
            idFormGroup="store-group"
            label="Seleccionar Sucursal"
            v-model="contactAndAddress.store"
            :options="storeOptions"
            :valueField="'id'"
            :textField="'name'"
          />
        </div>
      </div>

      <div v-if="user.user_information.deliver_to === 'address'" class="flex w-full mt-2">
        <div class="flex-grow-0 w-1/2 ml-2 h-16">
          <select-input
            class="w-full text-sm"
            idInput="store"
            idFormGroup="store-group"
            label="País"
            :options="countryOptions"
            v-model="country"
          />
        </div>
        <div class="flex-grow-0 w-1/2 ml-2 h-16">
          <select-input
            class="w-full text-sm"
            idInput="store"
            idFormGroup="store-group"
            label="Provincia"
            :options="provinces"
            @action="loadCities"
            v-model="province"
          />
        </div>
        <div class="flex-grow-0 w-1/2 ml-2 h-16">
          <select-input
            class="w-full text-sm"
            idInput="store"
            idFormGroup="store-group"
            label="Ciudad"
            :options="cities"
            v-model="city"
            @action="loadDistricts"
          />
        </div>
        <div class="flex-grow-0 w-1/2 ml-2 h-16">
          <select-input
            class="w-full text-sm"
            idInput="store"
            idFormGroup="store-group"
            label="Distrito"
            :options="districts"
            v-model="district"
          />
        </div>
      </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import RadioButtons from '@/components/RadioButtons.vue';
import UserMixin from '@/mixins/UserMixin';
import request from '@/api/request-services';

export default {
  name: 'ContactAndAddress',
  mixins: [UserMixin],
  components: { InputField, SelectInput, RadioButtons },
  data() {
    return {
      contactAndAddress: {
        phoneHouse: '22936831',
        extHouse: '',
        cellPhone: '87056609',
        workPhone: '',
        extWork: '',
        otherPhone: '',
        extOther: '',
        deliveryIn: 'sucursal',
        store: null,
      },
      storeOptions: [],
      countryOptions: [
        { text: 'Costa Rica', value: 1 },
      ],
      deliveryOptions: [
        { text: 'Dirección', value: 'address' },
        { text: 'Sucursal', value: 'office' },
      ],
      country: null,
      provinces: [],
      province: null,
      cities: [],
      city: null,
      districts: [],
      district: null,
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    async loadProvinces() {
      const response = await request.requestExternalDataGET('https://ubicaciones.paginasweb.cr/provincias.json');

      if (response.status === 200) {
        const keys = Object.keys(response.data);
        for (let i = 0; i < keys.length; i += 1) {
          this.provinces.push({
            text: response.data[keys[i]],
            value: i + 1,
          });
        }
      }
    },
    async loadCities() {
      const response = await request.requestExternalDataGET(`https://ubicaciones.paginasweb.cr/provincia/${this.province}/cantones.json`);
      this.cities.splice(0);
      if (response.status === 200) {
        const keys = Object.keys(response.data);
        for (let i = 0; i < keys.length; i += 1) {
          this.cities.push({
            text: response.data[keys[i]],
            value: i + 1,
          });
        }
      }
    },
    async loadDistricts() {
      const response = await request.requestExternalDataGET(`https://ubicaciones.paginasweb.cr/provincia/${this.province}/canton/${this.city}/distritos.json`);
      this.districts.splice(0);
      if (response.status === 200) {
        const keys = Object.keys(response.data);
        for (let i = 0; i < keys.length; i += 1) {
          this.districts.push({
            text: response.data[keys[i]],
            value: i + 1,
          });
        }
      }
    },
    async loadStores() {
      const response = await request.requestGET('branch_offices', this.getToken);
      if (response.status === 200) {
        this.storeOptions = response.data;
      }
    },
  },
  mounted() {
    this.loadProvinces();
    this.loadStores();
  },
};
</script>
